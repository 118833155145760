import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';

import * as fromStore from '@app/claims/store';
import { filter } from 'rxjs/operators'

@Injectable()
export class ClaimExistsGuards  {
  constructor(private store: Store<fromStore.State>) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.store.dispatch(new fromStore.GetClaim({ _id: route.params.claimId }))
    return this.store.select(fromStore.getClaimLoaded)
      .pipe( filter(i => i) )
  }
}
