<div class="">
  <div class="bg-white p-3 rounded-2xl">
    <form action="">
      <div class="wrapper">
        <div class="wrapper-left">
          <div class="">
            <app-input
              [idInfo]="'claim-number'"
              [label]="'Claim'"
              [type]="'number'"
              [isFilterInput]="true"
              [disableValidation]="true"
              [debounceEnable]="true"
              [value]="filters.autoIncr"
              (onChange)="onSet.emit({autoIncr:$event})"
            ></app-input>
          </div>
          <div class="">
            <app-input
              [idInfo]="'claim-ref'"
              [label]="'Ref#'"
              [isFilterInput]="true"
              [disableValidation]="true"
              [debounceEnable]="true"
              [value]="filters.refNumber"
              (onChange)="onSet.emit({refNumber:$event})"
            ></app-input>
          </div>
          <div class="">
            <app-input
              [idInfo]="'claim-order'"
              [label]="'Order#'"
              [isFilterInput]="true"
              [disableValidation]="true"
              [debounceEnable]="true"
              [value]="filters.orderNumber"
              (onChange)="onSet.emit({orderNumber:$event})"
            ></app-input>
          </div>
          <div class="">
            <app-input
              *ngIf="filters?.claimantContactId"
              [label]="'Customer'"
              [idInfo]="'claim-claimant'"
              [value]="filters._claimant_name"
              [isReadOnly]="true"
              [options]="{canRemove: true}"
              (onRemove)="onSet.emit({claimantContactId: null, _claimant_name: null})"
            ></app-input>
            <app-search
              *ngIf="!filters.claimantContactId"
              [label]="'Customer'"
              [resource]="'CONTACT'"
              [defaultFilter]="'active'"
              [idInfo]="'filter-claimant'"
              [options]="{noFocus: true, leftMarginMiddle: true}"
              [method]="'search'"
              [filter]="{limit: 25, sort: 'quoteCount', dir: 'desc', filterName: filters?._claimant_name, hasClaims: true}"
              (onChange)="
              onSet.emit({
                claimantContactId: $event._id,
                _claimant_name: $event.name
              })"
            ></app-search>
          </div>
          <div>
            <div *ngIf="filters.carrierId" class="">
              <app-input
                [label]="'Carrier'"
                [idInfo]="'claim-carrierName'"
                [value]="filters.carrierName"
                [isReadOnly]="true"
                [options]="{canRemove: true}"
                (onRemove)="onSet.emit({
                  carrierId: null,
                  carrierName: null,
                  driverName: null,
                  driverId: null
                  })"
              ></app-input>
            </div>
            <app-search
              *ngIf="!filters.carrierId"
              [label]="'Carrier'"
              [resource]="'CONTACT'"
              [idInfo]="'filter-carrierId'"
              [options]="{noFocus: true}"
              [method]="'search'"
              [filter]="{type: 'CARRIER', limit: 25, sort: 'quoteCount', dir: 'desc', filterName: filters?.carrierName, hasOrders: true, isAccount: true}"
              (onChange)="
                setValue('carrierId', $event._id);
                onSet.emit({
                  carrierId: $event._id,
                  carrierName: $event.name,
                  driverName: null,
                  driverId: null
                })
              "
            ></app-search>
          </div>
          <div>
            <app-input
              *ngIf="filters?.driverId"
              [label]="'Driver'"
              [value]="filters.driverName"
              [idInfo]="'claim-driverName'"
              [isReadOnly]="true"
              [options]="{canRemove: true}"
              (onRemove)="onSet.emit({
                driverName: null,
                driverId: null
              })"
            ></app-input>
            <app-search
              *ngIf="!filters.driverId"
              [label]="'Driver'"
              [resource]="'CONTACT'"
              [idInfo]="'filter-driver'"
              [method]="'search'"
              [defaultFilter]="'active'"
              [filter]="{type: 'DRIVER', limit: 25, accountId: form.value.carrierId, sort: 'quoteCount', dir: 'desc', filterName: filters?.driverName, isAccount: false}"
              (onChange)="setValue('driverId', $event._id); onSet.emit({driverId: $event._id, driverName: $event.name })"
            ></app-search>
          </div>
          <div class="">
            <app-date
              [parent]="filters"
              [title]="'Start Date'"
              [label]="'Start Date'"
              [startKey]="'startDate'"
              (onSave)="onSet.emit({'startDate': $event.startDate})"
            ></app-date>
          </div>
          <div class="">
            <app-date
              [parent]="filters"
              [title]="'End Date'"
              [label]="'End Date'"
              [startKey]="'endDate'"
              (onSave)="onSet.emit({'endDate': $event.endDate})"
            ></app-date>
          </div>
          <div class="units-filter">
            <div class="relative">
              <app-search
                [idInfo]="'equipment-search'"
                [label]="'Unit'"
                [resource]="'EQUIPMENT'"
                [isReadOnly]="!filters?.carrierId"
                [options]="{focus: true}"
                [method]="'search'"
                [filter]="{carrierId: filters?.carrierId}"
                (onChange)="setUnit($event._id, $event.name, $event.equipmentType)"
              ></app-search>
            </div>
            <div class="units" *ngIf="filters?.units">
              @for(unit of filters?.units; track unit.unitId){
                <div
                  class="unit-item"
                  role="group"
                  aria-label="Small button group"
                >
                  <div class="flex rounded-lg font-medium items-center"
                  [ngClass]="{'unitTruckName': unit?.equipmentType === 'TRUCK', 'unitTrailerName': unit?.equipmentType === 'TRAILER', 'unitOtherName': unit?.equipmentType === 'OTHER'}"
                  >
                    <i class="fa-solid unit-icon" [ngClass]="{'fa-truck': unit?.equipmentType === 'TRUCK', 'fa-trailer': unit?.equipmentType === 'TRAILER'}" [ngStyle]="{'color': (unit?.equipmentType === 'TRUCK') ? '#1E429F' : (unit?.equipmentType === 'TRAILER') ? '#5521B5' : ''}"></i>
                    <span class="ml-2 name">{{unit?.unitName}}</span>
                    <app-btn-confirm
                      style="margin-left: auto !important"
                      [color]="(unit?.equipmentType === 'TRUCK') ? '#3F83F8' : (unit?.equipmentType === 'TRAILER') ? '#9061F9' : (unit?.equipmentType === 'OTHER') ? '#d6d6d6' : ''"
                      (onConfirm)="removeUnit(unit.unitId)"
                    ></app-btn-confirm>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div class="right">
          <div class="wrapper-right">
            <div class="">
              <app-input
                [idInfo]="'claim-order-ref'"
                [label]="'OrderRef.#'"
                [isFilterInput]="true"
                [disableValidation]="true"
                [debounceEnable]="true"
                [value]="filters.orderRefNumber"
                (onChange)="onSet.emit({orderRefNumber:$event})"
              ></app-input>
            </div>
            <div class="">
              <app-select
                [label]="'Assignees'"
                [keyName]="{key:'_id', name:'name'}"
                [idInfo]="'claim-assignee'"
                [list]="users"
                [options]="{users:true}"
                [value]="filters.assignee || ''"
                [placeholder]="'All Assignees'"
                (onSave)="onSet.emit({ assignee: $event })"
              ></app-select>
            </div>
              <div class="">
                <app-select
                  [label]="'Tags'"
                  [keyName]="{key:'key', name:'name'}"
                  [idInfo]="'claim-source'"
                  [list]="sources"
                  [options]="{noFirstOption: true}"
                  [value]="filters.source || ''"
                  (onSave)="onSet.emit({ source: $event })"
                ></app-select>
              </div>
              <div class="" *ngIf="form.value.carrierId && filters.carrierId">
                <div *ngIf="filters.dispatchId">
                  <app-input
                    [label]="'Dispatch'"
                    [isFilterInput]="true"
                    [disableValidation]="true"
                    [idInfo]="'claim-dispatchId'"
                    [debounceEnable]="true"
                    [isReadOnly]="true"
                    [options]="{canRemove: true}"
                    [value]="filters.dispatchName"
                    (onRemove)="onSet.emit({
                      dispatchName: null,
                      dispatchId: null
                    })"
                  ></app-input>
                </div>
                <app-search
                  *ngIf="!filters.dispatchId"
                  [label]="'Dispatch'"
                  [resource]="'CONTACT'"
                  [accountId]="form.value.carrierId"
                  [idInfo]="'filter-dispatchId'"
                  [method]="'search'"
                  [defaultFilter]="'active'"
                  [filter]="{type: 'DISPATCH', limit: 25, accountId: form.value.carrierId, sort: 'quoteCount', dir: 'desc', filterName: filters?.driverName, isAccount: false}"
                  (onChange)="setValue('dispatchId', $event._id); onSet.emit({dispatchId: $event._id, dispatchName: $event.name })"
                ></app-search>
              </div>
              <div class="">
                <app-input
                  [isFilterInput]="true"
                  [label]="'Vehicles'"
                  [idInfo]="'claim-vehicleName'"
                  [disableValidation]="true"
                  [debounceEnable]="true"
                  [value]="filters.vehicleName" (onChange)="onSet.emit({vehicleName:$event})">
                </app-input>
              </div>
              <div class="">
                <app-input
                  [isFilterInput]="true"
                  [label]="'VIN'"
                  [disableValidation]="true"
                  [idInfo]="'claim-vin'"
                  [debounceEnable]="true"
                  [value]="filters.VIN" (onChange)="onSet.emit({VIN:$event})">
                </app-input>
              </div>
          </div>
          <div class="flex items-start">
            <div class="">
              <div class="flex">
                <button type="button" class="type_stage" [ngClass]="{'stage-bth-active-new': filters?.stage == 'NEW'}"
                  (click)="filters?.stage !== 'NEW' ? onSet.emit({ stage: 'NEW' }) : null" title="New">
                  <i class="fas fa-fire stage-new"></i>
                </button>
                <button type="button" class="type_stage" [ngClass]="{'stage-bth-active-cold': filters?.stage == 'COLD'}"
                  (click)="filters?.stage !== 'COLD' ? onSet.emit({ stage: 'COLD' }) : null" title="Cold">
                  <i class="fas fa-fire stage-cold"></i>
                </button>
                <button type="button" class="type_stage" [ngClass]="{'stage-bth-active-warm': filters?.stage == 'WARM'}"
                  (click)="filters?.stage !== 'WARM' ? onSet.emit({ stage: 'WARM' }) : null" title="Warm">
                  <i class="fas fa-fire stage-warm"></i>
                </button>
                <button type="button" class="type_stage" [ngClass]="{'stage-bth-active-hot': filters?.stage == 'HOT'}"
                  (click)="filters?.stage !== 'HOT' ? onSet.emit({ stage: 'HOT' }) : null" title="Hot">
                  <i class="fas fa-fire stage-hot"></i>
                </button>
                <button *ngIf="filters?.stage" type="button" class="type_stage"
                  (click)="onSet.emit({ stage: '' })">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div class="flex types-filters">
              <button type="button" class="type_type" [ngClass]="{'bg-blue-100': filters?.type == 'AUTO'}" (click)="onSet.emit({'type': filters?.type == 'AUTO' ? '' : 'AUTO'})">
                Auto</button>
              <button type="button" class="type_type" [ngClass]="{'bg-blue-100': filters?.type == 'TRUCK'}" (click)="onSet.emit({'type': filters?.type == 'TRUCK' ? '' : 'TRUCK'})">
                Truck</button>
              <button type="button" class="type_type" [ngClass]="{'bg-blue-100': filters?.type == 'OTHER'}" (click)="onSet.emit({'type': filters?.type == 'OTHER' ? '' : 'OTHER'})">
                Other</button>
            </div>
          </div>
        </div>






      </div>
      <div class="claim-filter-btns">
        <button class="add-claim claim-filter-button" (click)="addClaim()">
          + ADD CLAIM
        </button>
        <button class="claims-reset claim-filter-button" (click)="clearAll()"
        [ngClass]="{'disabled': loading}"
        [disabled]="loading">
          Reset
        </button>
      </div>
    </form>
  </div>
</div>

