import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap, mergeMap, catchError, tap, takeUntil } from 'rxjs/operators';
import * as ClaimActions from '@app/claims/store/actions/claim.actions';
import * as CoreActions from '@app/core/store/actions';
import { ClaimService } from '@app/claims/services/claim.service';
import { ToastHelper } from '@app/core/services/toast.service';
import { AccountService } from '@app/shared'

@Injectable()
export class ClaimEffects {
  
  getClaims$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClaimActions.GetClaims>(ClaimActions.ClaimActionTypes.GET),
    switchMap(action => {
      return this._ClaimService.getAll(action.payload.filters).pipe(
        map(resp => {
          return new ClaimActions.GetClaimsSuccess({
            claims: resp.data.items || [],
            totalCount: resp.totalCount,
            statsCount: resp.data.statsCount,
            subStatsCount: resp.data.subStatsCount,
          });
        }),
        catchError(error => of(new ClaimActions.GetClaimsError(error)))
      );
    })
  ));

  // getShops$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType<ClaimActions.GetClaims>(ClaimActions.ClaimActionTypes.GET_SHOPS),
  //     mergeMap((action: any) => {
  //       return this._accountService
  //         .getAll({ isAccount: true, ...action.payload.filters, withAddress: true })
  //         .pipe(
  //           takeUntil(this.actions$.pipe(ofType(AccountActions.AccountActionTypes.ADD_ONE))),
  //           map(resp => {
  //             // console.log({resp})
  //             return new AccountActions.GetAccountsSuccess({
  //               accounts: resp.data || [],
  //               totalCount: resp.totalCount || 0
  //             })
  //           }),
  //           catchError(error => of(new AccountActions.GetAccountsError(error))),
  //         )
  //     })
  //   )
  // })

  getShops$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClaimActions.GetClaims>(ClaimActions.ClaimActionTypes.GET_SHOPS),
    switchMap(action => {
      return this._accountService.getAll({ isAccount: true, ...action.payload.filters, withAddress: true }).pipe(
        map(resp => {
          return new ClaimActions.GetShopsSuccess(resp.data);
        }),
        catchError(error => of(new ClaimActions.GetShopsError(error)))
      );
    })
  ));

  
  getOneClaim$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClaimActions.GetClaim>(ClaimActions.ClaimActionTypes.GET_ONE),
    mergeMap((action: ClaimActions.GetClaim) => {
      return this._ClaimService.get(action.payload._id, action.payload.params).pipe(
        mergeMap(resp => this.GET_CLAIM_SUCCESS(resp.data, [new ClaimActions.GetClaimSuccess({ claim: resp.data })])),
        catchError(error => {
          this.notify.error(error);
          return of(new ClaimActions.GetClaimError(action.payload));
        })
      );
    })
  ));

  
  newClaimFromList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClaimActions.NewClaimFromList>(
      ClaimActions.ClaimActionTypes.NEW_Claim_FROM_LIST
    ),
    mergeMap(action => {
      return this._ClaimService.post(action.payload).pipe(
        switchMap(resp => [
          new CoreActions.ToastsOk('New Claim Added Successful!'),
          new ClaimActions.CreateClaimSuccess({
            claim: resp.data
          }),
          new ClaimActions.NewClaimFromListSuccess({
            claim: resp.data
          }),
        ]),
        catchError(error => {
          this.notify.error(error);
          return of(new ClaimActions.NewClaimFromListError(error));
        })
      );
    })
  ));

  
  newClaimRedirect$ = createEffect(() => this.actions$.pipe(
    ofType<ClaimActions.NewClaimFromListSuccess>(
      ClaimActions.ClaimActionTypes.NEW_Claim_FROM_LIST_SUCCESS
    ),
    tap(action => {
      this._Router.navigate([`/claims/${action.payload.claim._id}`]);
    })
  ), { dispatch: false });

  
  updateClaim$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClaimActions.UpdateClaim>(ClaimActions.ClaimActionTypes.UPDATE),
    switchMap(action =>
      this._ClaimService.put(action.payload.claim.id, action.payload.claim.changes)
        .pipe(
          mergeMap(resp => this.GET_CLAIM_SUCCESS(resp.data, [
            new CoreActions.ToastsOk('Claim update was Successful!'),
            new ClaimActions.UpdateClaimSuccess({
              claim: { id: resp.data._id, changes: resp.data }
            })
          ])),
          catchError(error => {
            this.notify.error(error);
            return of(
              new ClaimActions.UpdateClaimError(action.payload.claim.changes)
            );
          })
        )
    )
  ));

  
  patchClaim$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClaimActions.PatchClaim>(ClaimActions.ClaimActionTypes.PATCH),
    switchMap(action => {
      return this._ClaimService
        .patch(action.payload.claim.id, action.payload.claim.changes)
        .pipe(
          mergeMap(resp => this.GET_CLAIM_SUCCESS(resp.data, [
            new CoreActions.ToastsOk(' Claim Update Successful!'),
            new ClaimActions.PatchClaimSuccess({
              claim: { id: resp.data._id, changes: resp.data }
            })
          ])),
          catchError(error => {
            this.notify.error(error);
            return of(
              new ClaimActions.PatchClaimError(action.payload.claim.changes)
            );
          })
        );
    })
  ));

  
  deleteClaim$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClaimActions.DeleteClaim>(ClaimActions.ClaimActionTypes.DELETE),
    mergeMap(action => {
      return this._ClaimService.delete(action.payload.claim._id).pipe(
        map((data: Response) => {
          this.notify.ok('Claim Deleted Successful!');
          if (action.payload.redirect) {
            this._Router.navigate(['/claims']);
          }
          return new ClaimActions.DeleteClaimSuccess(data);
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new ClaimActions.DeleteClaimError(action.payload.claim));
        })
      );
    })
  ));


  
  apiGeneratePDF$ = createEffect(() => this.actions$.pipe(
    ofType<ClaimActions.ApiGeneratePDF>(
      ClaimActions.ClaimActionTypes.API_GENERATE_PDF
    ),
    switchMap(action => {
      return this._ClaimService
        .getPDF(action.payload.claim._id)
        .pipe(
          mergeMap(resp => [
            new ClaimActions.ApiGeneratePDFSuccess(action.payload),
            new ClaimActions.BrowserTriggerDownload({
              data: resp,
              name: `Claim_${action.payload.claim.autoIncr}.pdf`
            })
          ]),
          catchError(error =>
            of(
              new ClaimActions.ApiGeneratePDFError({
                error,
                order: action.payload.claim
              })
            )
          )
        );
    })
  ));


  
  downloadClaimFile$ = createEffect(() => this.actions$.pipe(
    ofType<ClaimActions.BrowserTriggerDownload>(
      ClaimActions.ClaimActionTypes.BROWSER_TRIGGER_DOWNLOAD
    ),
    tap(action => {
      console.log(action);
      const blob = new Blob([action.payload.data], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = action.payload.name;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    })
  ), { dispatch: false });

  GET_CLAIM_SUCCESS = (claim, actions = []) => {
    const contacts = [
      claim?._local?.claimant,
      claim?._local?.claimantContacts,
      claim?._local?.carrier,
      claim?._local?.pointOfContact,
      ...(claim?._local?.drivers || []),
      ...(claim?._local?.dispatchers || []),
    ].filter(c => c)
    const stops = [
      claim?._local?.origin,
      claim?._local?.destination
    ].filter(s => s)
    return [
      ...actions,
      new CoreActions.GetContactsByIdsSuccess({ contacts }),
      new CoreActions.GetVehiclesSuccess({ vehicles: claim?._local?.vehicles || [] }),
      new CoreActions.GetStopsSuccess({ items: stops }),
      new CoreActions.GetNotificationsSuccess({ notifications: claim?._local?.notifications }),
      claim?._local?.order?._id ? new CoreActions.GetOrderSuccess({ order: claim._local.order }) : null
    ].filter(i => i)
  }

  constructor(
    private _Router: Router,
    private _ClaimService: ClaimService,
    private actions$: Actions,
    private notify: ToastHelper,
    private _accountService: AccountService
  ) {}
}
