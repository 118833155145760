<div class="text-sm text-black rounded-xl shadow-lg bg-gray-100" *ngIf="location?.lat">
  <div class="bg-white rounded-xl">
    <div class="vehicle_location_head">
      <div class="vehicle_location_title">
          <span>Vehicle Location</span>
          <button (click)="onClose.emit()">
            <i class="fa-solid fa-xmark"></i>
          </button>
      </div>
      <div class="location-details">
        <div class="flex flex-col location-details-item">
          <span class="location_key">Location</span>
          <span class="location_value">{{address}}</span>
        </div>
        <div class="flex flex-col location-details-item">
          <span class="location_key">Status</span>
          <span class="location_value">{{status}}</span>
        </div>
        <div class="flex flex-col location-details-item">
          <span class="location_key">Refresh</span>
          <span class="location_value">{{refreshTime && refreshTime !== 0 ? refreshTime : 1}} Min ago</span>
        </div>
      </div>
    </div>
    <div class="vehicle_location_map" style="width: 500px;" *ngIf="location?.lat">
      <google-map        
        width="500px"
        height="197px"
        #map
        [zoom]="7"
        [center]="{lat:location?.lat, lng:location?.lng}" 
      >
        <map-marker
          #somemarker="mapMarker"
          [position]="{lat:location?.lat, lng:location?.lng}"
          [icon]="{url: 'assets/map_pins/general_map_pins/car.png'}"
        >
        </map-marker>
      </google-map>
    </div>
  </div>
</div>