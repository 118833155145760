import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import {NgxPopperjsModule} from 'ngx-popperjs';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ClaimHistoryComponent } from '@app/claims/components/claim-history.component';
import { ClaimDetailsComponent } from '@app/claims/components/claim-details.component';
import { ClaimsListComponent } from '@app/claims/components/claim-list.component';
import { AdvanceFiltersComponent } from '@app/claims/components/advance-filters.component';

import { CoreComponentsModule } from '@app/shared/buttons';
import { SharedModule } from '@app/shared/shared.module';
import { PipesModule } from '@app/shared/pipes';
import { GoogleMapsModule } from '@angular/google-maps'
import { VehicleLocation } from './vehicle-location/vehicle-location.component'

export const COMPONENTS = [
  ClaimsListComponent,
  ClaimDetailsComponent,
  ClaimHistoryComponent,
  AdvanceFiltersComponent,
  VehicleLocation
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgxDatatableModule,
    CoreComponentsModule,
    SharedModule,
    GoogleMapsModule,
    PipesModule,
    NgxPopperjsModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ComponentsModule {}
