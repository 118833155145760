import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { tap, debounceTime, distinctUntilChanged, takeUntil, withLatestFrom, map } from 'rxjs/operators'

import * as fromClaimStore from '@app/claims/store'
import * as fromCoreStore from '@app/core/store'
import * as fromSidebarStore from '@app/sidebar/store'
import * as fromUserStore from '@app/users/store'
import { Claim } from '@app/claims/models/claim.model'
import { User } from '@app/shared/models/user.model'
import { getUser } from '@app/users/store'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-claims',
  template: `
    <div class="">
      <div *ngIf="filters" class="">

        <app-claim-list
          [claims]="claims"
          [totalCount]="totalCount$ | async"
          [coreVals]="coreVals$ | async"
          [stats]="stats$ | async"
          [subStats]="subStats$ | async"
          [loading]="loading$ | async"
          [users]="users$ | async"
          [claimFilters]="filters"
        ></app-claim-list>
      </div>
    </div>
  `,
  styles: [
    `
      .quote-list__container {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    `
  ]
})
export class ClaimsComponent implements OnInit, OnDestroy {
  claims$: Observable<Claim[]>
  totalCount$: Observable<number>
  users$: Observable<User[]>
  // filters$: Observable<any>

  coreVals$: Observable<any>
  payments$: Observable<any>
  vehiclesVals$: Observable<any>
  loading$: Observable<any>
  stats$: Observable<any>
  subStats$: Observable<any>

  selectedStatusKey = 'claim-list'
  statusPopoverKey = 'popover'
  currentUser: User
  isLoggedIn
  claims
  filters

  destroyed$ = new Subject<boolean>()

  constructor(
    private store: Store<fromClaimStore.State>,
    private userStroe: Store<fromUserStore.State>,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.users$ = this.store.select(fromSidebarStore.selectAllUsers)
    this.coreVals$ = this.store.select(fromCoreStore.selectCoreValClaims)
    this.payments$ = this.store.select(fromCoreStore.selectCoreValPayments)
    this.loading$ = this.store.select(fromClaimStore.getClaimsLoading)
    this.claims$ = this.store.select(fromClaimStore.selectAllClaims)

    this.store.select(fromClaimStore.selectAllClaims).pipe(
      takeUntil(this.destroyed$),
      tap(claims => {
        this.claims = claims
      })
    ).subscribe()


    this.totalCount$ = this.store.select(fromClaimStore.selectedTotalCount)
    this.stats$ = this.store.select(fromClaimStore.selectAllStats)
    this.subStats$ = this.store.select(fromClaimStore.selectAllSubStats)
    this.store.select(fromUserStore.getLoggedIn).pipe(
      takeUntil(this.destroyed$),
      tap(loggedIn => {
        this.isLoggedIn = loggedIn
      })
    ).subscribe()

    this.store.select(fromClaimStore.selectAllFilters).pipe(
      takeUntil(this.destroyed$),
      tap(filters => {
        if(this.isLoggedIn && filters){
          let currentFilter = {}
          currentFilter = filters

          let claimFilters: any = {}
          claimFilters = window.localStorage.getItem('claim-filters')
          claimFilters = JSON.parse(claimFilters)
          if (claimFilters) {
            currentFilter = {...currentFilter, ...claimFilters}
            Object.keys(currentFilter).forEach(key => (currentFilter[key] === "" || currentFilter[key] === null) && delete currentFilter[key]);
          }
          if (currentFilter) {
            this.filters = currentFilter
          }
          this.store.dispatch(new fromClaimStore.GetClaims({ filters: this.filters }))
        }
    })
    ).subscribe()
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
    localStorage.removeItem('claim-filters');
  }
}
