import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef
} from '@angular/core'
import { OpenModal } from '@app/modals/store/actions/layout.actions'

import { Store } from '@ngrx/store'
import { Subject, fromEvent, of } from 'rxjs'
import { tap, takeUntil, catchError, map } from 'rxjs/operators'

import { MULTI_SELECT_PRESET_VALUE_CONFIG } from '@app/shared/buttons/select.config'

import * as fromStore from '@app/claims/store'
import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'
import * as fromTrackingStore from '@app/tracking/store';
import { NgxPopperjsTriggers, NgxPopperjsPlacements, NgxPopperjsContentComponent } from 'ngx-popperjs'
import { User, Note, OrderVehicle, Contact, Order, FOLLOW_UP_RESOURCE, genMockOrderVehicle } from '@app/shared/models'
import { Claim } from '@app/claims/models/claim.model'
import { NotesComponent } from '@app/shared/components/notes.component'
import { multiselectGetValues, getUpdatedArr, getClearedArr, setFieldsPermissions } from '@app/shared/utils'
import { SessionEndService } from '@app/shared/services/session-end.service'
import { PriceComponent } from '@app/shared/buttons/price.component'
import { environment } from 'environments/environment'
import { popupService } from '@app/shared/services/popup.service'
import { AccountService, EquipmentService, GeocodeService, GpsService } from '@app/shared'
import { VehicleLocation } from './vehicle-location/vehicle-location.component'
import { FieldTypes } from '@app/shared'
import { ClaimService } from '../services'

@Component({
  selector: 'app-claim-details',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './claim-details.component.html',
  styleUrls: ['./claim-details.component.css']
})
export class ClaimDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(NotesComponent, { static: false })
  notesComp: NotesComponent
  @ViewChild('driverSearch', { static: false }) driverSearch: NgxPopperjsContentComponent;
  @ViewChild('driverCarrierSearch', { static: false }) driverCarrierSearch: NgxPopperjsContentComponent;
  @ViewChild('carrierSearch', { static: false }) carrierSearch: NgxPopperjsContentComponent;
  @ViewChild('claimantSearch', { static: false }) claimantSearch: NgxPopperjsContentComponent;
  @ViewChild('driverSearchInput') driverSearchInput: ElementRef<any>;
  // @ViewChild('appSelectWrapper') appSelectWrapper: ElementRef;
  // @ViewChild('vehicleShowDetails', { static: false }) vehicleShowDetails: NgxPopperjsContentComponent;

  @ViewChild(PriceComponent, { static: false })
  appPrice: PriceComponent
  @Input()
  set setStops(val) {
    if (val) {
      this.stops = val
    }
  }
  showVehicleLocation = false
  stops
  EDIT_VIEW = false
  claimPage = 'notes'
  fileView = 'claim'

  searchContacts = []
  searchCarrierContacts = []
  searchCarriers = []
  claimantContacts = []

  @Input()
  locked: boolean
  @Input()
  set claimItem(v) {
    if (v) {
      this.claim = v

      // this.cdr.detectChanges()
      this.source = v?.source
      this.totalOfResponsibleMinusSettlement = this.getMinSettlement()
      this.totalAdminAndDriversFees = this.getAdminAndDriversFees()

      this.lowestQuote = null
      this.lowestQuoteShop = null
  
      for (let key in this.claim?.estimates) {
        if (Object.prototype.hasOwnProperty.call(this.claim?.estimates, key)) {
          const est = this.claim?.estimates[key];
          if (est?.isLowestQuote) {
            this.lowestQuote = est?.price || ''
            this.lowestQuoteShop = est?.shop
          }
        }
      }
  
      if (this._contacts?.length) {
        this._contacts = this._contacts.map(c => this.fillContactNotifications(c))
      }
      setTimeout(() => document.body.click(), 0)
    }
  }
  claim: Claim

  @Input()
  set contacts(val) {
    this._contacts = val.map(c => this.fillContactNotifications(c))
  }
  _contacts

  NgxPopperjsTriggers = NgxPopperjsTriggers
  NgxPopperjsPlacements = NgxPopperjsPlacements

  @Input()
  vehicles: any[]
  @Input()
  set setOrder (val) {
    this.order = val
  }

  @Input()
  order: Order
  @Input()
  currentUser: User
  @Input()
  users: User[]
  @Input()
  notes: Note[]
  @Input()
  lastModifyClaim
  @Input()
  notifications
  @Input()
  contactTypes

  @Input()
  set coreVals(v) {
    this.stages[status] = [{ name: 'ALL' }, ...(v.stages || [])]
    this.statuses = [...(v.statuses || [])]
    this.subStatuses = [...(v.subStatuses || [])]

    this.paymentMethods = [...(v.paymentMethods || [])]
    // this.sources = v.sources.map(t => t.name)
    this.sources = [...(v.sources || [])].map(i => ({
      value: i.key,
      label: i.name
    }))
  }

  @Input()
  permissions

  @Output()
  onUpdate = new EventEmitter<Claim>()
  @Output()
  onRemove = new EventEmitter<Claim>()
  @Output()
  onSend = new EventEmitter<Object>()

  claimTabType = 'carrier'
  destroyed$ = new Subject<boolean>()
  loggedIn$
  totalOfResponsibleMinusSettlement = 0
  totalAdminAndDriversFees = 0
  currentClaimShop
  orderType = 'AUTO'

  editState = false
  _permission
  isCompleted = false
  selectedStatus = ''

  statuses = []
  subStatuses = []
  stages = {}
  paymentMethods = []
  sources = []
  multiSelectConfig: any = MULTI_SELECT_PRESET_VALUE_CONFIG

  FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE
  lowestQuote = null
  lowestQuoteShop: User
  arrayOfStrings = []
  resourceType = 'orders'
  resourceId: string
  loading = false;
  showHistory = false
  leg
  location = null
  pointOfContactFilterByClaimant = false
  refreshTime
  newDocument = false
  documentType = ''
  fullAddress
  source: string[]
  isReadOnly: boolean
  activeSearchContact = 'active'

  qbUrl = environment.qbUrl

  _multiselectGetValues = multiselectGetValues
  _getUpdatedArr = getUpdatedArr
  _getClearedArr = getClearedArr

  constructor(
    private store: Store<fromStore.State>, 
    private userStore: Store<fromUserStore.State>, 
    private sessionEndService: SessionEndService,
    private popupService: popupService,
    private cdr: ChangeDetectorRef,
    public geocodeService: GeocodeService,
    private _EquipmentService: EquipmentService,
    private gpsService: GpsService,
    private _AccountService: AccountService,
    private claimService: ClaimService
    ) {
      claimService.patchLocal$.pipe(takeUntil(this.destroyed$)).subscribe(data=>{
        if (data?.contact?._id) {
          if (data.type == 'driver') {
            return this.addAdditionalDriver(data?.contact)
          }
          if (data.type == 'claimant') {
            return this.addClaimantContact(this.claim?.claimant?.accountId ? 'contacts' : 'accountId', data?.contact)
          }
          this.patchClaim({ [data.type]: data?.contact?._id })
        }
      })
     }

  ngOnInit() {
    this.loggedIn$ = this.userStore.select(fromUserStore.getLoggedIn)
    fromEvent(document, 'keydown')
      .pipe(
        takeUntil(this.destroyed$),
        tap((e: any) => (e.keyCode === 9 && this.locked ? e.preventDefault() : null))
      )
      .subscribe()
    this.sessionEndService
      .setTimerToOpenSessionModal({
        preOpenConfig: {
          timeout: 1200
        },
        postOpenConfig: {
          logOutUrl: '/claims/',
          type: 'Claim',
          text: 'Lock will be ended',
          timeout: 1200
        }
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe()

    if (!this.permissions || !this.permissions['claims_update']) {
      this.isReadOnly = true
    }
  }

  async openShopsNearby() {
    if (this.location == null) {
      for (let s of this.stops) {
        if (this.order.status == 'DELIVERED' && this.order.destinationId == s._id) {
          let fullAddress = ''
          for (const [key, value] of Object.entries(s?.account?.address)) {
            if (key != '_id' && key != 'addressLine2' && value != '' && value != null) {
              fullAddress += `${value} `
            }
          }
          await this.geocodeService.getCoordinatesByAddress(fullAddress).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
            const location = { 
              lat: res.data.lat, 
              lng: res.data.lng 
            }
              // console.log('current location DELIVERED: ', location)
              this.location = location
              this.fullAddress = fullAddress
              this.store.dispatch(
                new OpenModal({
                  type: 'CLAIM_SHOPS_NEARBY',
                  props: {
                    windowClass : "claim-shops-nearby-modal",
                    location: this.location
                  },
                })
              )
          })
        }
        if (this.order.status == 'ORDER' && this.order.originId == s._id) {
          let fullAddress = ''
          for (const [key, value] of Object.entries(s?.account?.address)) {
            if (key != '_id' && key != 'addressLine2' && value != '' && value != null) {
              fullAddress += `${value} `
            }
          }
          await this.geocodeService.getCoordinatesByAddress(fullAddress).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
            const location = { 
              lat: res.data.lat, 
              lng: res.data.lng 
            }
            // console.log('current location ORDER: ', location)
            this.location = location
            this.fullAddress = fullAddress
            this.store.dispatch(
              new OpenModal({
                type: 'CLAIM_SHOPS_NEARBY',
                props: {
                  windowClass : "claim-shops-nearby-modal",
                  location: this.location
                },
              })
            )
          })
        }
      }
      if (this.order.status == 'PICKED-UP') {
        if (this.claim?.equipment?.truck?._id) {
          this._EquipmentService.getEquipmentsCoordinates([this.claim?.equipment?.truck?._id]).pipe(
            takeUntil(this.destroyed$),
            tap(resp => {
              // console.log(resp.data[this.claim?.equipment?.truck?._id])
              this.location = resp.data[this.claim?.equipment?.truck?._id]._location.gps
              this.store.dispatch(
                new OpenModal({
                  type: 'CLAIM_SHOPS_NEARBY',
                  props: {
                    windowClass : "claim-shops-nearby-modal",
                    location: this.location
                  },
                })
              )
            })
          )
          .subscribe()
        } else {
          let driver = this.getContactById(this.order?.carrier?.driverIds[0])
          await this.gpsService.get({userId: driver?._id})
          .pipe(
            takeUntil(this.destroyed$),
            tap(resp => {
              if (resp.data.length) {
                for (let user of resp.data) {
                  if (user.userId == driver?._id) {
                    

                    // console.log(user.odometer.time);
                    // console.log(new Date());
                  
                    this.location = { 
                      lat: user.coordinates[0][0], 
                      lng: user.coordinates[0][1]
                    }
  
                    this.store.dispatch(
                      new OpenModal({
                        type: 'CLAIM_SHOPS_NEARBY',
                        props: {
                          windowClass : "claim-shops-nearby-modal",
                          location: this.location
                        },
                      })
                    )
                  }
                }
              }
            })
          )
          .subscribe()
        }
      }
    } else {
      this.store.dispatch(
        new OpenModal({
          type: 'CLAIM_SHOPS_NEARBY',
          props: {
            windowClass : "claim-shops-nearby-modal",
            location: this.location
          },
        })
      )
    }

  }

  async openVehicleLocation(vehicle) {
      for (let s of this.stops) {
        if (this.order.status == 'DELIVERED' && this.order.destinationId == s._id) {
          let fullAddress = ''
          for (const [key, value] of Object.entries(s?.account?.address)) {
            if (key != '_id' && key != 'addressLine2' && value != '' && value != null) {
              fullAddress += `${value} `
            }
          }
          this.fullAddress = fullAddress
          await this.geocodeService.getCoordinatesByAddress(fullAddress).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
            const location = { 
              lat: res.data?.data ? res.data?.data?.lat : res.data?.lat, 
              lng: res.data?.data ? res.data?.data?.lng : res.data?.lng
            }
              // console.log('current location DELIVERED: ', location)
              this.location = location
              vehicle.showVehicleLocation = true
              // this.vehicleShowDetails.show()
              this.cdr.detectChanges()
              return
          })
        }
        if (this.order.status == 'ORDER' && this.order.originId == s._id) {
          let fullAddress = ''
          for (const [key, value] of Object.entries(s?.account?.address)) {
            if (key != '_id' && key != 'addressLine2' && value != '' && value != null) {
              fullAddress += `${value} `
            }
          }
          this.fullAddress = fullAddress
          await this.geocodeService.getCoordinatesByAddress(fullAddress).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
            const location = { 
              lat: res.data?.data ? res.data?.data?.lat : res.data?.lat, 
              lng: res.data?.data ? res.data?.data?.lng : res.data?.lng
            }
            // console.log('current location ORDER: ', location)
            this.location = location
            vehicle.showVehicleLocation = true
            // this.vehicleShowDetails.show()
            this.cdr.detectChanges()
            return

          })
        }
      }
      if (this.order.status == 'PICKED-UP') {
        if (this.claim?.equipment?.truck?._id) {
          this._EquipmentService.getEquipmentsCoordinates([this.claim?.equipment?.truck?._id]).pipe(
            takeUntil(this.destroyed$),
            tap(resp => {

              if (resp.data.data) {
                this.location = resp.data.data[this.claim?.equipment?.truck?._id]._location.gps
              } else {
                this.location = resp.data[this.claim?.equipment?.truck?._id]._location.gps
              }
              // console.log(resp.data.data)

              this.geocodeService.getAddressByCoordinates(this.location.lat, this.location.lng).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
                let fullAddress = ''
                for (const [key, value] of Object.entries(res.data)) {
                  if (key != '_id' && key != 'addressLine2' && value != '' && value != null) {
                    fullAddress += `${value} `
                  }
                }
                this.fullAddress = fullAddress
                // console.log('current location PICKED-UP: ', location)
                vehicle.showVehicleLocation = true
                // this.vehicleShowDetails.show()
                this.cdr.detectChanges()
                return
              })
            })
          )
          .subscribe()
        } else {
          let driver = this.getContactById(this.order?.carrier?.driverIds[0])
          // console.log(driver)
          await this.gpsService.get({userId: driver?._id})
          .pipe(
            takeUntil(this.destroyed$),
            tap(resp => {

              let data 
              if (resp.data?.data) {
                data = resp.data?.data
              } else {
                data = resp.data
              }
              if (data.length) {
                for (let user of data) {
                  if (user.userId == driver?._id) {
                    this.location = { 
                      lat: user.coordinates[0][0], 
                      lng: user.coordinates[0][1]
                    }

                    const dateString = user.odometer.time;
                    const targetDate = new Date(dateString);
                    const currentDate = new Date();
                    const differenceInMillis: number = currentDate.getTime() - targetDate.getTime();
                    const differenceInMinutes: number = Math.floor(differenceInMillis / (1000 * 60));
                    this.refreshTime = differenceInMinutes

                    
                    if (this.location.lat && this.location.lng) {
                      this.geocodeService.getAddressByCoordinates(this.location.lat, this.location.lng).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
                        let fullAddress = ''
                        for (const [key, value] of Object.entries(res.data)) {
                          if (key != '_id' && key != 'addressLine2' && value != '' && value != null) {
                            fullAddress += `${value} `
                          }
                        }
                        this.fullAddress = fullAddress
                        vehicle.showVehicleLocation = true
                        // this.vehicleShowDetails.show()
                        this.cdr.detectChanges()
                        return
                      })
                    }
                  }
                }
              }
            })
          )
          .subscribe()
        }
      }
  }

  patchClaim = (claim: Partial<Claim> | any) => {
    this.store.dispatch(
      new fromStore.PatchClaim({
        claim: {
          id: this.claim._id,
          changes: claim
        }
      })
    )
  }

  patchAdjuster = (name) => {
    let creator = {
      creator: {
        ...this.claim.creator,
        name
      }
    }
    this.store.dispatch(
      new fromStore.PatchClaim({
        claim: {
          id: this.claim._id,
          changes: creator
        }
      })
    )
  }
  
  patchClaimCharges = (claim: Partial<Claim> | any) => {
    let changes = {
      ...claim,
      'amounts.charges.updatesDate': new Date(),
    }
    
    this.patchClaim(changes)
  }

  formatPhone(phone) {
    if (phone.length == 10) {
      return '(' + phone?.substring(0, 3) + ') ' + phone?.substring(3, 6) + '-' + phone?.substring(6, 10)
    }
    else {
      return phone
    }
  }
  
  saveSource = source => {
    this.source = source
    let diff = this.source
      .filter(x => !this.claim.source?.includes(x))
      .concat(this.claim.source?.filter(x => !this.source.includes(x)))

    if (diff?.length) {
      this.patchClaim({ source: this.source || [] })
    }
  }

  changeContact(contactId) {
    this.store.dispatch(
      new OpenModal({
        type: 'EditContact',
        props: { contactId }
      })
    )
  }

  openAdjustersNotes(vehicle?) {
    this.store.dispatch(
      new OpenModal({
        type: 'ADJUSTERS_NOTES',
        props: {
          windowClass : "claim-adjusters-notes-modal",
          props: { vehicle, notes: this.claim?.adjusters?.notes || [], claimId: this.claim?._id }
        },
      })
    )
  }

  deleteShop(type) {
    let trackShop = this.claim.estimates[type]
    trackShop = {
      ...trackShop,
      shop: {}
    }
    let estimates = {
      ...this.claim.estimates,
      [type]: trackShop
    }
    this.putClaim({estimates})
    // console.log({estimates})
  }

  setShop(type, shop) {
    if (!this.claim?.estimates) {
      this.claim.estimates = {
        [type]: {
          shop
        }
      }
    } else {
      let trackShop = this.claim?.estimates[type]

      trackShop = {
        ...trackShop,
        shop
      }

      if (!trackShop?.shop?.name || trackShop.shop?.name == '') {
        trackShop.isLowestQuote = false
      }

      let estimates = {
        ...this.claim.estimates,
        [type]: trackShop
      }

      this.claim.estimates = estimates
      
    }

    this.putClaim({estimates: this.claim.estimates})
    // console.log(this.claim.estimates)
  }

  setShopPrice(type, price) {
    if (!this.claim?.estimates) {
      this.claim.estimates = {
        [type]: {
          price
        }
      }
    } else {
      let trackShop = this.claim?.estimates[type]

      trackShop = {
        ...trackShop,
        price
      }

      if (!trackShop?.price || trackShop?.price == '') {
        trackShop.isLowestQuote = false
      }

      let estimates = {
        ...this.claim.estimates,
        [type]: trackShop
      }

      this.claim.estimates = estimates
      
    }
    // console.log(this.claim.estimates)
    this.putClaim({estimates: this.claim.estimates})
  }

  
  setActiveShopId (type, event) {
    let objectKeys = Object.keys(this.claim.estimates).filter(key => key !== 'shopActive')
    for (let key of objectKeys) {
      this.claim.estimates[key].isLowestQuote = false;
    }
    this.claim.estimates[type] = {
      ...this.claim.estimates[type],
      isLowestQuote: event
    }

    this.putClaim({estimates: this.claim.estimates})
  }
  
  setClaimOrder = order => {
    let claim = this.claim
    if (claim.type === 'AUTO') {
      claim.claimant = {
        accountId: order.item.billing ? order.item.billing.accountId : null,
        contacts: []
      }
    }

    this.store.dispatch(new fromCoreStore.GetContactsByIds({ids:[order.item.carrier.contacts]})) // simple fix because i think there is no need to create yet new effect for only this problem
    setTimeout(() => {
      let damageNotes = []
      for (let vehicle of order.item.vehicles) {
        damageNotes.push({
          vehicleId: vehicle?._id,
          title: `${vehicle?.year || ''} ${vehicle?.make || ''} ${vehicle?.model || ''}`,
          content: ''
        })
      }
      this.patchClaim({
        orderId: order.item._id,
        location: {
          ...this.claim.location,
          originId: order.item.originId,
          destinationId: order.item.destinationId
        },
        vehicles: order.item.vehicles.map(v => v._id),
        damageNotes
      })
    }, 1000)
  }

  removeAdditionalDriver = driver =>
    this.patchClaim({
      additionalDriverIds: this.claim.additionalDriverIds?.filter(id => id !== driver._id) || [],
      driversFault: this.claim.driversFault?.filter(id => id !== driver?._id)
    })

  addAdditionalDriver = (driver: Contact) =>
    this.patchClaim({ additionalDriverIds: [...this.claim.additionalDriverIds, driver._id] })

  getMinSettlement() {
    const carrier = (this.claim.carrierAtFault && this.claim.amounts?.responsible?.carrier) || 0
    const drivers =
      this.claim.driversFault?.reduce(
        (acc, driverId) => acc + (this.claim.amounts?.responsible?.drivers[driverId] || 0),
        0
      ) || 0
    const dispatcher = (this.claim.dispatcherAtFault && this.claim.amounts?.responsible?.dispatcher) || 0
    const insurance = this.claim.amounts?.responsible?.insurance || 0
    const settlement = this.claim.amounts?.charges?.settlement || 0
    return carrier + drivers + dispatcher + insurance - settlement
  }

  getAdminAndDriversFees = () => (Object.values(this.claim.amounts?.responsible?.drivers || {}).reduce((acc, val) => acc + val, 0)).tf2

  async notify() {
    const notifyClaim = await this.claimFullData(this.claim)
    this.onSend.emit({
      ...notifyClaim
    })
  }

  async sendFiles(files) {
    const notifyClaim = await this.claimFullData(this.claim)
    this.onSend.emit({
      ...notifyClaim,
      files
    })
  }

  async claimFullData(claim) {
    const driverContacts = claim._local?.drivers || []
    const claimantContact = this.getContactById(claim.claimant.accountId)

    // Add to notify all phones and email Task #2108 
    const notify = claim?.notify.length ? claim.notify : []

    claimantContact?.phones?.map(phone => {
      if(notify?.find(n => n.contactTypeId === phone._id)) return
      notify.push({
        contactId: claimantContact._id,
        contactTypeId: phone._id,
        type: 'phone',
        typeId: null
      })
    })

    claimantContact?.emails?.map(email => {
      if(notify?.find(n => n.contactTypeId === email._id)) return
      notify.push({
        contactId: claimantContact._id,
        contactTypeId: email._id,
        type: 'email',
        typeId: null
      })
    })

    for (let driver of driverContacts) {
      driver?.phones?.map(phone => {
        if(notify?.find(n => n.contactTypeId === phone._id)) return
        notify.push({
          contactId: driver._id,
          contactTypeId: phone._id,
          type: 'phone',
          typeId: null
        })
      })

      driver?.emails?.map(email => {
        if(notify?.find(n => n.contactTypeId === email._id)) return
        notify.push({
          contactId: driver._id,
          contactTypeId: email._id,
          type: 'email',
          typeId: null
        })
      })
    }

    const notifyClaim = {
      claim: {
        ...claim,
        notify,
        claimant: {
          ...claim.claimant,
          contacts: [claimantContact]
        },
        driver: {
          contacts: driverContacts
        }
      }
    }

    return notifyClaim
  }

  setDispatcherFault = (dispatcherId, checked) => {
    this.patchClaim({
      dispatchersAtFault: checked
        ? [dispatcherId]
        : [],
      dispatcherAtFault: checked
    })
  }

  setDriversFault = (driverId, checked) =>
    this.patchClaim({
      driversFault: checked
        ? [...(this.claim?.driversFault || []), driverId]
        : this.claim.driversFault?.filter(v => v !== driverId) || []
    })

  putClaim = claim => {
    this.store.dispatch(
      new fromStore.UpdateClaim({
        claim: {
          id: this.claim._id,
          changes: claim
        }
      })
    )
  }

  addClaimantContact = (type, contact) => {
    let claimant
    if (type !== 'accountId') {
      claimant = this.claim?.claimant
      if (!this.claim?.claimant?.contacts?.includes(contact?._id)) {
        claimant['contacts'] = [...this.claim?.claimant.contacts || [], contact._id]
      }
    } else {
      claimant = {
        accountId: contact._id,
      }
    }
    this.patchClaim({ claimant })
  }

  removeClaiment (type, id) {
    if (type !== 'accountId') {
      let contacts = this.claim?.claimant.contacts.filter(c => c !== id)
      this.patchClaim({
        claimant: {
          accountId: this.claim?.claimant?.accountId,
          contacts
        }
      })
    } else {
      this.patchClaim({
        claimant: {
          contacts: []
        }
      })
    }
  }

  patchDriverResponsibleAmount = (driverId: string, val: number) =>
    this.patchClaim({
      'amounts.responsible.drivers': {
        ...(this.claim.amounts.responsible.drivers || {}),
        [driverId]: val
      }
    })

  openHistory = () => (this.showHistory = true)

  hideHistory = () => (this.showHistory = false)

  findIndexContact(array, contact) {
    if (!array) {
      return
    }
    const index = array.findIndex(id => id === contact)
    if (index === -1) {
      array.push(contact)
    } else {
      array[index] = contact
    }
  }

  addActiveDocument = type => {
    type.completed = !type.completed
    this.patchClaim({ checklist: this.removeLocalVar(this.claim.checklist) })
  }

  setClaimType(type) {
    if (this.claim) {
      let vehicleIds = []
      let orderId = this.claim.orderId
      let claimant = this.claim.claimant

      if (type !== 'TRUCK' && this.order) {
        vehicleIds = this.order.vehicles
      }

      if (type === 'TRUCK') {
        orderId = null
      } else if (type === 'AUTO' && orderId) {
        claimant = {
          accountId: this.order.billing.accountId,
          contacts: [this.order.billing.accountId]
        }
      } else if (type !== 'OTHER' && this.claim.claimant && this.claim.claimant.accountId) {
        claimant = {
          accountId: null,
          contacts: []
        }
      }

      const patchObj: Partial<Claim> = {
        vehicles: vehicleIds,
        type,
        orderId,
        claimant
      }

      if (type === 'AUTO' || orderId) {
        patchObj.customCarrierId = null
        patchObj.customDispatcherId = null
      }

      this.patchClaim(patchObj)
    }
  }

  addDocumentType = () => {
    this.patchClaim({
      checklist: [
        ...(this.claim.checklist || []),
        {
          name: this.documentType,
          completed: false
        }
      ]
    })
    this.documentType = ''
  }

  removeLocalVar = array =>
    array.map(items => {
      delete items._id
      return items
    })

  fillContactNotifications(contact) {
    ;['emails', 'phones'].map(type => {
      if (contact && contact[type] && contact[type].length) {
        contact[type] = contact[type].map(c => {
          return { ...c, notify: false }
        })
      }
    })
    if (contact && this.claim?.notify?.length) {
      for (const notify of this.claim.notify) {
        if (notify.contactId === contact._id) {
          const key = notify.type + 's'
          if (contact[key] && contact[key].length) {
            for (const k in contact[key]) {
              const cI = contact[key][k]
              if (cI._id === notify.contactTypeId) {
                contact[key][k].notify = true
              }
            }
          }
        }
      }
    }

    return contact
  }

  getContactById = (id: string) => this._contacts?.find(c => c._id === id)

  getStopById = (id: string) => this.stops?.find(stop => stop._id === id)

  setAssignee(assigneeId: string) {
    const assignee = this.users.find(user => user._id === assigneeId)
    if (assignee) {
      const data = (({ _id, name }) => ({ _id, name }))(assignee)
      console.log(data)
      this.patchClaim({ assignee: data })
    }
  }

  updateDate = (type, content) =>
    this.store.dispatch(
      new fromStore.PatchClaim({
        claim: {
          id: this.claim._id,
          changes: { [type]: content }
        }
      })
    )

  updateAddress = event =>
    this.store.dispatch(
      new fromStore.PatchClaim({
        claim: {
          id: this.claim._id,
          changes: {
            location: {
              address: event
            }
          }
        }
      })
    )

  removeCarrier = (type, typeId) => {
    this.claim[type] = null
    this.patchClaim({ [type]: this.claim[type] })
  }

  removeVehicle = async vehicle => {
    (await this.popupService.confirm('Are you sure you want to delete?')) &&
    this.patchClaim({
      vehicles: this.claim.vehicles?.filter(v => v !== vehicle._id) || [],
      damageNotes: this.claim?.damageNotes.filter(n => n?.vehicleId !== vehicle._id)
    })
  }

  removeChecklistItem = item =>
    this.patchClaim({
      checklist: this.claim.checklist?.filter(items => items._id !== item._id) || []
    })

  generatePdf = () => this.store.dispatch(new fromStore.ApiGeneratePDF({ claim: this.claim }))

  canDeleteVehicle () {
    let vehicle: FieldTypes = {action: this.locked || this.isReadOnly || this.claim?.type != 'TRUCK' ? 'r' : 'crud' }
    let adjustment: FieldTypes

    let permissionVehicle = { vehicle, adjustment }
    const fields = setFieldsPermissions(permissionVehicle)
    let _permission = fields

    return _permission
  }

  addVehicle = () => {
    this.store.dispatch(
      new fromCoreStore.CreateVehicle({
        resource: 'claims',
        resourceId: this.claim._id,
        vehicle: genMockOrderVehicle(),
        successActionList: v => [
          new fromStore.PatchClaim({
            claim: {
              id: this.claim._id,
              changes: { 
                vehicles: this.claim.vehicles.concat([v?._id]),
                damageNotes: [
                  ...this.claim?.damageNotes,
                  {
                    vehicleId: v?._id,
                    content: '',
                    title: `${v?.year || ''} ${v?.make || ''} ${v?.model || ''}`
                  }
                ]
              }
            }
          })
        ]
      })
    )
  }

  getAddress (item) {
    if (item?.state == '' && item?.city == '' && item?.addressLine1 == '') {
      return ''
    } else if (item?.state == '' && item?.city == '' && item?.addressLine1 !== '') {
      return item?.addressLine1
    } else if (item?.state !== '' && item?.city !== '' && item?.zip == '') {
      return [
        item?.addressLine1,
        item?.city,
        item?.state,
        'USA'
      ].join(', ')
    } else {
      return [
        item?.addressLine1,
        item?.city,
        item?.state,
        item?.zip,
        'USA'
      ].join(', ')
    }
  } 

  updatePaymentNote = (value) => {
    let payment = {
      ...this.claim.payment,
      notes: value
    }
    this.putClaim({payment})
  }

  updateNote = (type, value) => {

    if (this.claim[type]?.length == value?.length) {
      return
    }
    this.putClaim({[type]: value})
  }

  setStringFor(str) {
    return str.replace(/\s+/g, '');
  }

  clickEmptyNote(note) {
    note.edit = true
  }

  updateDamageNote = (note, value) => {

    if (note.content?.length == value?.length) {
      return
    }

    note.content = value

    delete note.edit
    this.putClaim({damageNotes: this.claim?.damageNotes})
  }

  deleteVehicle = async (vehicle) =>
    (await this.popupService.confirm('Are you sure you want to delete?')) &&
    this.store.dispatch(
      new fromCoreStore.DeleteVehicle({
        resource: 'claims',
        resourceId: this.claim._id,
        vehicle,
        successActionList: () => [
          new fromStore.PatchClaim({
            claim: {
              id: this.claim._id,
              changes: { vehicles: this.claim.vehicles?.filter(id => id !== vehicle._id) }
            }
          })
        ]
      })
    )

  createVehicle = vehicle => {
    if (document.hasFocus()) {
      this.store.dispatch(
        new fromCoreStore.UpdateVehicle({
          resource: 'claims',
          resourceId: this.claim._id,
          vehicle: {
            id: vehicle._id,
            changes: vehicle
          },
          successActionList: v => [
            new fromStore.PatchClaim({
              claim: {
                id: this.claim._id,
                changes: { vehicles: [...(this.claim.vehicles || []), v._id] }
              }
            })
          ]
        })
      )
    }
  }

  updateVehicle = vehicle =>
    this.store.dispatch(
      new fromCoreStore.UpdateVehicle({
        resource: 'claims',
        resourceId: this.claim._id,
        vehicle: {
          id: vehicle._id,
          changes: vehicle
        }
      })
    )

  findNotifyIndexContact = (contact, itemContact) =>
    this.claim.notify.findIndex(element => {
      if (
        'contactId' in element &&
        'contactTypeId' in element &&
        element.contactId.toString() === contact._id.toString() &&
        element.contactTypeId.toString() === itemContact._id.toString()
      ) {
        return true
      } else {
        return false
      }
    })

  updateClaimContact = (contact) => {
    this.store.dispatch(
      new fromCoreStore.PatchContact({
        contact: {
          id: contact._id,
          changes: contact
        }
      })
    )
  }
  updateClaimContactNotify(contact) {
    const typeArr = [
      { contact: 'phones', claim: 'phone' },
      { contact: 'emails', claim: 'email' }
    ]
    if (!Array.isArray(this.claim.notify)) {
      this.claim.notify = []
    }
    typeArr.forEach(type => {
      if(contact[type.contact]){
        contact[type.contact].map(itemContact => {
          const index = this.findNotifyIndexContact(contact, itemContact)
  
          if (itemContact.notify === true && index === -1) {
            this.claim.notify.push({
              contactId: contact._id,
              contactTypeId: itemContact._id,
              type: type.claim,
              typeId: contact.typeId
            })
            return
          }
          if (itemContact.notify === false && index !== -1) {
            this.claim.notify = this.claim.notify.filter(n => {
              return !(
                n.contactId.toString() === contact._id.toString() &&
                n.contactTypeId.toString() === itemContact._id.toString() &&
                n.type.toString() === type.claim.toString()
              )
            })
            return
          }
        })
      }
    })
    this.patchClaim({ notify: this.claim.notify })
  }
  trunc(value){
    return Math.trunc(+value)
  }

  setUnit(id, name, type) {
    this.claim.equipment = this.claim.equipment || {};
    // this.claim.equipment[type] = this.claim.equipment[type] || {};
    this.claim.equipment[type] = {_id: id, name}
    this.patchClaim({equipment: {...this.claim.equipment, [type]: this.claim.equipment[type]}})
  }
  removeUnit(type){
    delete this.claim.equipment[type]
    // console.log(this.claim.equipment)
    this.patchClaim({equipment: this.claim.equipment})
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  updateInsuranceContact = contact => {
    this.store.dispatch(
      new fromCoreStore.PatchContact({
        contact: { id: contact._id, changes: contact }
      })
    )
  }
}
