import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFeature from '@app/claims/store/reducers';
import * as fromFilter from '@app/claims/store/reducers/filter.reducers';

// Filters State
export const selectFilterState = createSelector(
  fromFeature.selectClaimsFeatureState,
  (state: fromFeature.State) => state.filters
);

export const selectAllFilters = createSelector(
  selectFilterState,
  fromFilter.getFilters
);
