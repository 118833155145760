<div class="m-portlet" style="box-shadow: none;">
  <div class="m-portlet__head">
    <div class="m-portlet__head-caption">
      <div class="m-portlet__head-title">
        <h3 class="m-portlet__head-text">
          History
        </h3>
      </div>
    </div>
  </div>
  <div class="m-portlet__body">
    <div class="tab-content">
      <div class="tab-pane active" id="m_widget2_tab1_content">
        <div class="m-widget2">
          @for(note of history.slice().reverse(); track $index){
            <div>
              <div class="m-widget2__item ">
                <div class="">
                  <div class="col-md-10" style="font-size: 1.15rem;">
                    {{note.action}}
                    <span *ngIf="note.content">: {{note.content}}</span>
                  </div>
                </div>
                <span class="m-widget2__user-name" style="float: right;">
                  <span *ngIf="note.followUp" style="background-color: #36A3F7; border-radius:3px; color:white;padding-left: 0.4rem;padding-right: 0.4rem; padding-top: 0.2rem;padding-bottom: 0.2rem; font-size: 0.8rem;margin-right: 0.3rem;">
                    Follow-up: {{note.followUp | date: 'short' }}</span>{{note.createdAt | date: 'short'}} |
                  <span *ngIf="note.creator"><app-account-info-popover [creator]="note?.creator"></app-account-info-popover></span>
                </span>
                <div class="separator"></div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
