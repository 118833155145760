import { Component, Input, OnDestroy, ViewChild, AfterViewInit, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core'
import { DatatableComponent } from '@swimlane/ngx-datatable'
import { SelectionType } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs'
import { Store } from '@ngrx/store'
import * as fromStore from '@app/claims/store'

import { Claim } from '@app/claims/models/claim.model'

import { ToastHelper } from '@app/core/services/toast.service'
import { filterCheck } from '@app/shared/utils/filterCheck.util'

import { OpenModal } from '@app/modals/store/actions/layout.actions'
import { User } from '@app/shared/models/user.model'
import { NgxPopperjsTriggers, NgxPopperjsPlacements } from 'ngx-popperjs'



import flatpickr from 'flatpickr';

@Component({
  selector: 'app-claim-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.css']
})
export class ClaimsListComponent implements OnDestroy, AfterViewInit, OnInit {
  @ViewChild(DatatableComponent, { static: false })

  table: DatatableComponent
  @Input()
  users: User[]
  @Input() set claims (v) {
    if (v) {
      for (let order of v) {
        order._displayedCars = 3 
      }
      this.currentClaims = v
    }
  }

  currentClaims

  // for (let order of this.orders) {
  //   order._displayedCars = 3 
  //   const today = new Date()
  //   const createdAt = new Date(order.createdAt)
  //   createdAt.setHours(createdAt.getHours() + 3)
  //   if (createdAt > today && order.status === 'NEW') {
  //     this.newOrders.push(order._id)
  //   }
  // }
  @Input()
  totalCount: number
  @Input()
  set claimFilters(v) {
    this.filters = v
    if (v && !this.filterTriggerCounter) {
      this.filterIsOpen = this._filterCheck(v)
      this.filterTriggerCounter++
    }
    this.pagination.offset = this.filters ? this.filters.page - 1 : 0
    this.pageFilter.page = this.filters ? this.filters.page : 1

    if (Object.keys(this.filters)) {
      let filters = {...this.filters};
      delete filters?.status
      this.filtersWidthoutStatus = filters
    }
  }
  filters
  filtersWidthoutStatus

  @Input()
  set coreVals(v) {
    if (v) {
      this._stages = Array.isArray(v?.stages) ? [{ key: 'ALL', name: 'ALL' }, ...v.stages] : [{ key: 'ALL', name: 'ALL' }]
      this._statuses = Array.isArray(v?.statuses) ? [{ key: 'ALL', name: 'ALL' }, ...v.statuses] : [{ key: 'ALL', name: 'ALL' }]
      this._subStatuses = Array.isArray(v?.subStatuses) ? [{ key: 'ALL', name: 'ALL' }, ...v.subStatuses] : [{ key: 'ALL', name: 'ALL' }]
      this._sources = Array.isArray(v?.sources) ? [{ key: 'ALL', name: 'ALL' }, ...v.sources] : [{ key: 'ALL', name: 'ALL' }]
    }
  }
  @Input()
  set stats(v) {
    if (v) {
      for (let status of this._statuses) {
        delete status.count
      }
      for (let val of v) {
        for (let k of this._statuses) {
          if (val._id == k.name) {
            k.count = val.count
          }
        }
      }
    }
  }
  @Input()
  set subStats(v) {
    if (v) {
      for (let subStat of this._subStatuses) {
        delete subStat.count
      }
      for (let val of v) {
        for (let k of this._subStatuses) {
          if (val._id.replace('_', ' ') == k.name) {
            k.count = val.count
          }
        }
      }
    }
  }

  @Input()
  sources
  @Input()
  payments
  @Input()
  loading

  _statuses = []
  _subStatuses = []
  _stages = []
  _sources = []

  destroyed$ = new Subject<boolean>()


  localSelectedOrders = []

  isActive = 0
  selected = []
  stockQuote: number
  selectQuote
  limitOptions = [{key:'25',name:'25'}, {key:'50',name:'50'}, {key:'75',name:'75'}, {key:'100',name:'100'}]

  filterTriggerCounter = 0

  currentPage = 1;

  statusCount: {}
  leadsCount = 0
  pagination = { limit: 25, offset: 0 }
  pageFilter = { limit: 25, page: 1 }
  defaultPageFilter = { limit: 25, page: 1 }
  isRemove: Boolean = false
  isSelected
  selectedClaims
  filterIsOpen = false

  NgxPopperjsTriggers = NgxPopperjsTriggers
  NgxPopperjsPlacements = NgxPopperjsPlacements

  _filterCheck = filterCheck

  SelectionType = SelectionType

  constructor(private store: Store<fromStore.State>, public notify: ToastHelper, private cdr: ChangeDetectorRef) {}

  newClaim() {
    this.store.dispatch(
      new OpenModal({
        type: 'NEW_CLAIM',
        props: {
          size: 'md'
        }
      })
    )
  }

  toggleListVehicles(row) {
    if (row?._displayedCars === 3) {
      row._displayedCars = row?._vehicles.length;
    } else {
      row._displayedCars = 3
    }
  }

  

  toTitleCase(str) {
    if (!str) {
      return ''
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  removeSelectClaims() {}

  selectClaim = order => {
    if(this.localSelectedOrders.findIndex(x => x._id === order._id) < 0){
      this.localSelectedOrders.push(order)
    }
    else{
      this.localSelectedOrders = this.localSelectedOrders.filter(x => x._id != order._id);
    }
    // this.store.dispatch(new fromCoreStore.ToggleSelectOrder(order._id))
    console.log(this.localSelectedOrders)
  }
  

  filterArray(value, arr) {
    for (const n of arr) {
      if (arr[n]['name'] === value) {
        return arr[n]['_id']
      }
    }
  }

  onActive(event) {
    event.type === 'click' && event.cellElement.blur()
  }

  delete(quotes) {
    this.store.dispatch(new fromStore.DeleteClaim({ claim: quotes, redirect: false }))
  }

  ngOnInit(): void {

  }

  onSort(event) {
    this.updateFilter(event)
  }

  ngAfterViewInit() {
    // Init Flat Picker AfterViewInit
    flatpickr('#filterDate', {
      locale: 'en',
      dateFormat: 'm/d/Y',
      enableTime: false,
      allowInput: true
    })
  }

  setPage(event) {
    const limit = this.filters.limit
    const page = event
    window.localStorage.setItem('claim-filters', JSON.stringify({ ...this.defaultPageFilter, ...this.filters, page }));
    this.store.dispatch(new fromStore.FiltersAdd({ page, limit }))
  }

  updateFilter(filter) {
    if (filter?.status === null) {
      filter.subStatus = null
    }
    if (filter?.status) {
      filter.subStatus = null
    }
    if (filter?.limit) {
      filter.limit = Number(filter.limit)
      filter.page = 1
    }
    window.localStorage.setItem('claim-filters', JSON.stringify({ ...this.defaultPageFilter, ...this.filters, ...filter }));
    this.store.dispatch(new fromStore.FiltersAdd({ ...this.defaultPageFilter, ...this.filters, ...filter }))
  }

  resetFilter(filter) {
    window.localStorage.setItem('claim-filters', JSON.stringify(filter));
    this.store.dispatch(new fromStore.FiltersReset(filter))
  }

  getSourceName(source) {
    const item = this._sources.find(v => v.key === source)
    return item ? item.name : source
  }

  getSubStatusName(subStatus) {
    const item = this._subStatuses.find(v => v.key === subStatus)
    return item ? item.name : subStatus
  }

  openContactInfo(accountId) {
    // this.store.dispatch(new fromCoreStore.GetNotes({ accountId }));
    // this.store.dispatch(new fromCoreStore.SetAccountId({ _id: accountId }));
    // this.store.dispatch(
    //   new fromCoreStore.GetContacts({ accountId: accountId })
    // );
    // this.store.dispatch(new fromCoreStore.GetContact({ _id: accountId }));
    this.store.dispatch(
      new OpenModal({
        type: 'EditContact',
        props: { contactId: accountId }
      })
    )
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
