import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';



import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, effects } from '@app/claims/store';
import { ClaimExistsGuards } from '@app/claims/guards';
import { ComponentsModule } from '@app/claims/components';
import { ClaimItemComponent } from '@app/claims/containers/claim-item.component';
import { ClaimsComponent } from '@app/claims/containers/claims.component';

import { SharedModule, CoreComponentsModule } from '@app/shared';
import { PipesModule } from '@app/shared/pipes';
import { GoogleMapsModule } from '@angular/google-maps'

const ClaimsRouting = RouterModule.forChild([
  {
    path: '',
    component: ClaimsComponent,
    data: { permissionKey: 'claims_read' }
  },
  {
    path: ':claimId',
    component: ClaimItemComponent,
    canActivate: [ClaimExistsGuards],
    data: { permissionKey: 'claims_read' }
  }
]);

@NgModule({
  imports: [
    CommonModule,
    ClaimsRouting,
    StoreModule.forFeature('claims', reducers),
    EffectsModule.forFeature(effects),
    GoogleMapsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CoreComponentsModule,
    ComponentsModule,
    PipesModule
  ],
  declarations: [ClaimsComponent, ClaimItemComponent],
  providers: [ClaimExistsGuards]
})
export class ClaimsModule {
  constructor() {
    console.log('[Module] : Loaded ClaimsModule');
  }
}
